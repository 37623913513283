body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Animalese;
  src: url(/src/static/fonts/FinkHeavy.woff);
}

main {
  overflow-x: auto;
}

.season1{
  background: url(/src/static/images/season1.png) fixed;
  background-size: 150px;
}

.season2{
  background: url(/src/static/images/season2.png) fixed;
  background-size: 150px;
}

.season3{
  background: url(/src/static/images/season3.png) fixed;
  background-size: 150px;
}

.season4{
  background: url(/src/static/images/season4.png) fixed;
  background-size: 150px;
}

.navbar {
  background: url(/src/static/images/NSwitch_AnimalCrossingNewHorizons_wood_texture.jpg);
  background-size:cover;
  font-family: Animalese;
  color: #FFCF47;
  text-shadow: 0 0 3px rgb(206, 169, 4), 2px 2px 5px rgb(99, 26, 26);
  /* border-bottom: rgb(104, 78, 9) solid 5px; */

}

.navbar-toggler {
  background-image: url(/src/static/images/yellowBurger.png);
  border: #FFCF47 solid;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 0 3px rgb(206, 169, 4), 2px 2px 5px rgb(99, 26, 26);
} 

.navbar-toggler-expand {
  background-color: #FFCF47;
  /* I'm keeping this in in case I want to revert. */
  /* background-color: transparent; */
  background-image: url(/src/static/images/orangeBurger.png);
  border: orange solid;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none;
  border-radius: 5px;
  width: 56px;
  height: 40px;
}

.nav-link:hover {
  color: orange;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

.titleGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: darkslateblue;
  width: clamp(320px, 100%, 480px);
  padding: 20px;
  border-radius: 20px;
  border: blueviolet solid 5px;
  margin: 20px 0 20px;
}

.titleGroup h3 {
  color: cornflowerblue;
}

.titleGroup h1 {
  color: #FFCF47;
  font-family: Animalese;
  /* text-size-adjust: 200%; */
}

.homeGrid {
  display: flex;
  /* gap: 20px; */
  margin: 20px;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.mainImage {
  width: clamp(250px, 20%, 20%);
  height: 100%;
  margin-right: 30px;
  margin-left: 25px;
  margin-bottom: 20px;
}

.homeGrid .welcomeText {
  background-color: rgb(173, 173, 173);
  color:rgb(94, 80, 80);
  border-radius: 20px;
  border: rgb(73, 60, 60) 5px solid;
}

.welcomeText {
  padding: 20px 30px 20px;
  width: clamp(320px, 100%, 1000px);
}

.welcomeText h3 {
  display: flex;
  justify-content: center;
}

.welcomeText p {
  inline-size: auto;
  overflow-wrap: normal;
  text-align: justify;
}

.howItWorksLinks {
  display: flex;
  margin: -10px 0 5px;
  justify-content: center;
  gap: 20%;
  font-family: Animalese;
}

#caughtImg {
  width: 250px;
  float: left;
  margin: 5px;
  margin-left: 0;
  height: auto;
}

#buttonsImg {
  width: 300px;
  float: right;
  margin: 5px;
  margin-right: 0;
  height: auto;
}

#resultsImg {
  width: clamp(250px, 80%, 500px);
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  height: auto;
}

.itemGrid {
  display: grid;
  grid-template-columns: 110px 110px 110px 110px 110px 110px 110px 110px 110px 110px 110px 110px 110px 110px 110px 110px; 
  grid-template-rows: 110px 110px 110px 110px 110px;
  grid-auto-flow: column;
  gap: 10px;
  margin: 10px 0 15px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 5px;
  padding-top: 5px;
}

#creatureGrid {
  grid-template-columns: 110px 110px 110px 110px 110px 110px 110px 110px; 
  justify-content: center;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  font-size: x-small;
  width: 110px;
  height: 110px;
  border-radius: 20px;
  background-color: #F1EBC7;
  color: rgb(139, 119, 74);
  transition: width 0.1s, height 0.1s, transform 0.1s, background-color 0.3s, color 0.3s, opacity 0.3s, filter 0.3s;
}

.item:hover {
  width: 120px;
  height: 120px;
  transform: translate(-5px, -5px);
}

/* .critActive {
  This currently does nothing, but it used to. It's left in in case I want to revert.
} */

.critInactive {
  background-color: lightgray;
  color: gray;
  opacity: 80%;
  filter: grayscale(100%)
}

.item p {
  margin-bottom: 0px;
}

.item img {
  height: 50px;
}

.selectorsBox {
  left: 50%;
  /* transform: translate(-50%, 0); */
  display: flex;
  justify-content: center;
}

.selectors {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style-type: none;
  background-color: #F1EBC7;
  border-radius: 20px;
  width: 500px;
  height: 40px;
  padding-right: 35px;
  padding-left: 35px;
  /* margin-top: 20px; */
}

.selectorSplitter {
  color: rgb(88, 23, 23);
}

.selector {
  color: brown;
  cursor: pointer;
}

.selector:hover {
  color: rgb(194, 134, 56);
}

.resultButtonBox {
  display: flex;
  justify-content: center;
  /* align-content: center; */
  background-color: #F1EBC7;
  color: rgb(88, 23, 23);
  border: rgb(88, 23, 23) solid;
  border-radius: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  /* position: absolute; */
  position: relative;
  width: 180px;
  padding: 13px 20px 3px;
  box-shadow: 2px 2px 5px rgb(73, 60, 60);
  font-family: Animalese;
  cursor: pointer;
  margin-bottom: 20px;
}

.resultButtonBox:hover {
  color: #F1EBC7;
  border: #F1EBC7 solid;
  background-color: rgb(88, 23, 23);
}

results {
  background-color: darkslateblue;
  color: cornflowerblue;
  opacity: 1;
  /* max-width: 700px; */
  /* min-width: 320px; */
  width: clamp(320px, 100%, 800px);
  padding: 20px 25px 20px;
  border: blueviolet solid 5px;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  /* display: inline-block; */
  left: 50%;
  transform: translate(-50%, 0);
  /* overflow: hidden; */
  margin-top: 20px;
  margin-bottom: 20px;
}

results ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

results h2 {
  font-family: Animalese;
  color: #FFCF47;
  display: flex;
  justify-content: center;
}

results h4 {
  color: #FFCF47;
}

results h6 {
  text-transform: capitalize;
  color: rgb(178, 202, 247);
}

footer {
  /* background-color: rgb(83, 25, 25); */
  /* color: #8f864d; */
  font-size: small;
  border-top: rgb(104, 78, 9) solid 5px;
  background: url(/src/static/images/NSwitch_AnimalCrossingNewHorizons_wood_texture.jpg);
  background-size:cover;
  font-family: Animalese;
  color: #FFCF47;
  text-shadow: 0 0 3px rgb(206, 169, 4), 2px 2px 5px rgb(99, 26, 26);
}

.credits {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 15px;
  white-space: nowrap;
}

.creditColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.creditGroup {
  display: flex;
  flex-direction: row;
  margin: 0 0 -10px;
}

.selfGlorification {
  display: flex;
  justify-content: center;
}
  
  @media (max-width:500px) {
    .fishList {
      flex-direction: column;
    }
    .navbar {
      background-size: auto;
    }
    .titleGroup {
      margin-top: 0;
    }
    .homeGrid {
      margin-left: 0;
      margin-right: 0;
    }
    #buttonsImg {
      width: 250px;
    }
    .homeGrid {
      margin-bottom: 0;
    }
    results {
      margin-bottom: 0;
    }
    footer {
      font-size: xx-small;
    }
    .credits {
      flex-direction: column;
    }
    .creditColumn {
      align-items: center;
    }
  }

  @media (max-width:950px) {
    #creatureGrid {
      justify-content: left;
    }
  }

  @media (min-width:1910px) {
    .itemGrid {
      justify-content: center;
    }
  }